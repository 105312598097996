<template>
  <main>
    <UtilRendererContent
      v-if="content"
      :data="content"
    />
  </main>
</template>

<script setup>
definePageMeta({
    layout: 'loggedout',
    middleware: ['auth-guest'],
});

const { content, seo } = getPageContent('login');
useHead({
    title: seo.title,
});
</script>
